export default {
    mounted() {
      if (!this.$el.querySelectorAll) {
        return
      }
      const textFields = this.$el.querySelectorAll('.v-text-field input');
  
      textFields.forEach((input) => {
        input.addEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        });
      });
    }
};