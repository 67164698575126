import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from 'pinia'
import router from "./router";
import i18n from "./plugins/i18n";
import { useGlobalStore } from '@/store/globalStore';
import globalMixin from './globalMixin';

// Import custom global css
require('@/assets/style.css');

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia()

Vue.use(pinia);

Vue.mixin(globalMixin);

if (process.env.NODE_ENV === "production") {
  import("./plugins/keycloak").then((keycloak) => {
    keycloak.authentication.then(() => {
      // Set pinia store
      const globalStore = useGlobalStore();

      globalStore.setUser({ forename: keycloak.Keycloak.idTokenParsed.given_name, surname: keycloak.Keycloak.idTokenParsed.family_name, email: keycloak.Keycloak.idTokenParsed.email });
      globalStore.setTokens({ token: keycloak.Keycloak.token, refreshToken: keycloak.Keycloak.refreshToken })

      globalStore.init();

      new Vue({
        vuetify,
        pinia,
        router,
        i18n,
        render: (h) => h(App),
      }).$mount("#app");
    })
  })
} else {
  // Set pinia store
  const globalStore = useGlobalStore();

  globalStore.setUser({ forename: "Max", surname: "Mustermann", email: "test@keyed.de" });

  globalStore.init();

  new Vue({
    vuetify,
    pinia,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}
