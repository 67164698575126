import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import { CompanySettings } from '@/classes/CompanySettings'
import Vuetify from '@/plugins/vuetify'
import { useGlobalStore } from '@/store/globalStore';
import { UserCompany, UserSettings } from "@/classes/User";
import { License } from "@/classes/License";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  // Scroll to top after navigation
  scrollBehavior(/*to, from, savedPosition*/) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const globalStore = useGlobalStore();
  globalStore.toogleLoading();

  if (typeof to.params.companyId !== 'undefined') {
    // Check if company changed
    if (to.params.companyId !== from.params.companyId) {
      // Get company design
      CompanySettings.retrieve(to.params.companyId).then((res) => {
        Vuetify.framework.theme.themes.light = { ...Vuetify.framework.theme.themes.light, ...res.data.theme };
        Vuetify.framework.theme = { ...Vuetify.framework.theme, ...{ logo: res.data.logo } }
        // Set resource labels
        const store = useGlobalStore();
        store.setResourceLabels(res.data.resource_labels);
      });

      // Get company permissions
      License.retrieve(to.params.companyId).then((res) => {
        globalStore.setLicense(res.data);
      })

      UserSettings.list(to.params.companyId).then((res) => {
        globalStore.setUserLanguage(res.data.language);
      })

      // Get user permissions
      UserCompany.get_permissions(to.params.companyId).then((res) => {
        globalStore.setPerms(res.data);
      })
    }
  }

  if (to.name == "dashboard") {
    Vuetify.framework.theme.themes.light = new CompanySettings().theme;
  }

  next();
})

router.afterEach(() => {
  const globalStore = useGlobalStore();
  globalStore.toogleLoading();
});

export default router;
